import React, { useState } from "react";
import { buildClassName } from "../../utils";
import Icon from "../icon";
import ContactNavigation, { ContactNavigationProps } from "./contact-navigation";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";

interface ContactBubbleProps extends ContactNavigationProps {
  active?: boolean;
}

const ContactBubble: React.FC<ContactBubbleProps> = ({ contactNavigationSection, ...props }) => {
  const [active, setActive] = useState<boolean>(props.active ?? false);
  const { t } = useI18next();

  const handleToggle: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    setActive(!active);
  };

  const handleClose: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    setActive(false);
  };

  return (
    <div className={buildClassName(["contact-bubble", active && "contact-bubble--active"])}>
      <div className="contact-bubble__trigger" id="contact-bubble__open" onClick={handleToggle}>
        <Icon name="ui-chat" />
      </div>

      <div className="contact-bubble__frame">
        <button
          type="button"
          className="button button--arrow"
          id="contact-bubble__close"
          onClick={handleClose}
        ></button>

        <Icon name="ui-chat-outline" />

        <h4 className="contact-bubble__heading">
          <Trans>DO_YOU_HAVE_QUESTIONS</Trans>
          <br /> <Trans>WE_LOVE_TO_HELP_YOU</Trans>
        </h4>

        <p className="contact-bubble__text">
          <Trans>CONTACT_BUBBLE_DESCRIPTION</Trans>
        </p>

        <p className="contact-bubble__author">Lieven</p>

        <p className="contact-bubble__function">
          Lieven Bossaert,
          <br />
          {t("TRAVEL_EXPERT")}
        </p>

        <ContactNavigation contactNavigationSection={contactNavigationSection} />
      </div>
    </div>
  );
};

export default ContactBubble;
