import { BookingWizard } from "@qite/tide-booking-component/src";
import { useLocation } from "@reach/router";
import { useI18next } from "gatsby-plugin-react-i18next";
import { compact, trim } from "lodash";
import React, { useEffect, useState } from "react";
import { useMemberStore } from "../../app/member-store/context";
import { defaultLanguage } from "../../i18n/languages";
import icons from "../../images/travelworld_icons.svg";
import { TideItemForHotel, TideItemForRoundtrip } from "../../types";
import { generateProductPath } from "../../utils";
import Icon from "../icon";
import Loader from "../loader";
import { getNumbersFromParams } from "../../app/search-results/qsm/utils";

interface BookingProps {
  product: TideItemForHotel | TideItemForRoundtrip;
}

const Booking: React.FC<BookingProps> = ({ product }) => {
  const [memberState] = useMemberStore();
  const [tagIds, setTagIds] = useState([] as number[]);
  const [affiliateSlug, setAffiliateSlug] = useState<string>();
  const [agentAddressId, setAgentAddressId] = useState<number>();
  const { t, language } = useI18next();
  const location = useLocation();

  useEffect(() => {
    if (memberState?.member) {
      setAgentAddressId(memberState.member.agentId);
    }
  }, [memberState]);

  useEffect(() => {
    const slug = localStorage.getItem("as");
    if (slug) setAffiliateSlug(slug);

    const params = new URLSearchParams(location.search);
    const tagIds = getNumbersFromParams(params, "tagIds");
    if (tagIds) setTagIds(tagIds);
  }, []);

  if (!product?.content?.general?.product) {
    // CMS product hasn't got a PIM product reference
    return null;
  }

  const summaryCheckboxes = compact([
    t("SUMMARY_CHECKBOX_IDENTITY"),
    t("SUMMARY_CHECKBOX_OPTIONS"),
    t("SUMMARY_CHECKBOX_GENERAL_CONDITIONS", { interpolation: { escapeValue: false } }),
  ]).map((translation, index) => {
    return { id: `${index}`, text: translation, isSelected: false };
  });

  const productPath = `${trim(generateProductPath(product), "/")}`;
  const productPathLocalized =
    language == defaultLanguage ? `/${productPath}` : `/${language}/${productPath}`;

  return (
    <BookingWizard
      settings={{
        officeId: 1,
        language: language,
        currency: "EUR",
        bookingOptions: {
          b2b: {
            entryStatus: 2,
            customEntryStatusId: 8,
            tagIds: [9],
          },
          b2b2c: {
            entryStatus: 2,
            customEntryStatusId: 8,
            tagIds: [11],
          },
          b2c: {
            entryStatus: 1,
            customEntryStatusId: 3,
            tagIds: [11],
          },
        },
        tagIds: tagIds,
        agentRequired: true,
        agentAdressId: agentAddressId,
        includeFlights: true,
        generatePaymentUrl: true,
        productPath: productPathLocalized,
        basePath: `${productPathLocalized}/boeken`,
        options: {
          pathSuffix: "/",
        },
        travellers: {
          pathSuffix: "/travellers",
        },
        summary: {
          pathSuffix: "/overzicht",
          checkboxes: summaryCheckboxes,
        },
        confirmation: {
          pathSuffix: "/bevestiging",
        },
        error: {
          pathSuffix: "/mislukt",
        },
        companyContactEmail: "info@travelworld.be",
        companyContactPhone: "",
        showProductCardRating: false,
        showSidebarDeposit: true,
        sidebarHeaderComponent: (
          <div className="booking__guarantees">
            <Icon name="usp-checkbox" />
            <div className="booking__guarantees-copy">
              <h5>{t("BOOKING_GUARANTEES_TITLE")}</h5>
              <p>
                {t("BOOKING_GUARANTEES_DESCRIPTION", { interpolation: { escapeValue: false } })}
              </p>
              {/* Meer weten?{" "}
              <a href="" className="link">
                Ontdek onze garanties
              </a> */}
            </div>
          </div>
        ),
        loaderComponent: <Loader />,
        skipPaymentWithAgent: true,
        icons,
        affiliateSlug: affiliateSlug,
      }}
      productCode={product.content?.general?.product?.code ?? ""}
      productName={product.content?.general?.title ?? product.name}
      thumbnailUrl={product.content?.general?.thumbnailPicture?.url ?? undefined}
    />
  );
};

export default Booking;
