import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ContactBubble from "../components/contact-bubble";
import { Helmet } from "react-helmet";
import {
  TideItemConnection,
  TideItemForDestinations,
  TideItemForHotel,
  TideItemForNavigationSection,
  TideItemForNewsletterForm,
  TideItemForNotificationComponentConnection,
  TideItemForPage,
  TideItemForRoundtrip,
  TideItemForUspGroup,
  TideItemForWebsite,
} from "../types";
import { LookupQueryData } from "../components/qsm";
import Booking from "../components/booking";
import { first, isEmpty } from "lodash";
import NotificationBar from "../components/notification-bar";
import { AlternateRoute } from "../../.gatsby/gatsby-node";
import { useI18next } from "gatsby-plugin-react-i18next";

interface BookingPageTemplateProps {
  data: BookingPageData;
  pageContext: {
    language: string;
    alternateRoutes: AlternateRoute[];
  };
}

const BookingPageTemplate: React.FC<BookingPageTemplateProps> = ({ data, pageContext }) => {
  const { t } = useI18next();
  const notificationBar = first(data.notifications?.nodes);
  return (
    <Layout>
      <Helmet title={t("BOOK")} />
      {!isEmpty(notificationBar?.content?.general?.notification) && (
        <NotificationBar
          text={notificationBar?.content?.general?.notification ?? ""}
          icon={notificationBar?.content?.general?.iconFontAwesome ?? "fas fa-info"}
          backgroundColor={notificationBar?.content?.general?.backgroundColor ?? "#e74c3c"}
        />
      )}
      <Navbar
        alternateRoutes={pageContext.alternateRoutes}
        fullscreenPrimaryNavigationSection={data.fullscreenPrimaryNavigationSection}
        fullscreenSecondaryNavigationSection={data.fullscreenSecondaryNavigationSection}
        socialNavigationSection={data.socialNavigationSection}
        legalNavigationSection={data.legalNavigationSection}
        phone={data.website?.content?.contact?.phone ?? ""}
        showProductSearch={false}
      />
      <Booking product={data.product} />
      <Footer
        footerNavigationSection={data.footerNavigationSection}
        socialNavigationSection={data.socialNavigationSection}
        legalNavigationSection={data.legalNavigationSection}
        address={data.website?.content?.contact?.address ?? ""}
        phone={data.website?.content?.contact?.phone ?? ""}
        email={data.website?.content?.contact?.email ?? ""}
        uspGroup={data.footerUspGroup}
        newsletterForm={data.newsletterForm}
      />
      <ContactBubble contactNavigationSection={data.contactNavigationSection} />
    </Layout>
  );
};

interface BookingPageData extends LookupQueryData {
  page: TideItemForPage;
  product: TideItemForHotel | TideItemForRoundtrip;
  breadcrumb: TideItemConnection;
  website: TideItemForWebsite;
  notifications: TideItemForNotificationComponentConnection;
  navigationDestinations: TideItemForDestinations;
  mainNavigationSection: TideItemForNavigationSection;
  fullscreenPrimaryNavigationSection: TideItemForNavigationSection;
  fullscreenSecondaryNavigationSection: TideItemForNavigationSection;
  legalNavigationSection: TideItemForNavigationSection;
  socialNavigationSection: TideItemForNavigationSection;
  contactNavigationSection: TideItemForNavigationSection;
  footerNavigationSection: TideItemForNavigationSection;
  footerUspGroup: TideItemForUspGroup;
  newsletterForm: TideItemForNewsletterForm;
}

export const query = graphql`
  query BookingPageQuery($productId: String, $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    website: tideItemForWebsite(name: { eq: "Travelworld" }, language: { eq: $language }) {
      content {
        contact {
          phone
          email
          address
        }
      }
    }
    notifications: allTideItemForNotificationComponent(filter: { language: { eq: $language } }) {
      nodes {
        content {
          general {
            notification
            iconFontAwesome
            backgroundColor
          }
        }
      }
    }
    product: tideItem(id: { eq: $productId }) {
      name
      templateName
      ... on TideItemForHotel {
        content {
          general {
            title
            slug
            thumbnailPicture {
              url
            }
            product {
              tideId
              code
              name
            }
          }
        }
      }
      ... on TideItemForRoundtrip {
        content {
          general {
            title
            slug
            thumbnailPicture {
              url
            }
            product {
              tideId
              code
              name
            }
          }
        }
      }
      ...productPathFragment
    }
    fullscreenPrimaryNavigationSection: tideItemForNavigationSection(
      name: { eq: "Fullscreen Primary" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    fullscreenSecondaryNavigationSection: tideItemForNavigationSection(
      name: { eq: "Fullscreen Secondary" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    socialNavigationSection: tideItemForNavigationSection(
      name: { eq: "Social Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    contactNavigationSection: tideItemForNavigationSection(
      name: { eq: "Contact Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    legalNavigationSection: tideItemForNavigationSection(
      name: { eq: "Legal Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    footerNavigationSection: tideItemForNavigationSection(
      name: { eq: "Footer Navigation" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    footerUspGroup: tideItemForUspGroup(name: { eq: "Footer Usps" }, language: { eq: $language }) {
      ...uspGroupFields
    }
    newsletterForm: tideItemForNewsletterForm(
      name: { eq: "Newsletter" }
      language: { eq: $language }
    ) {
      content {
        externalMail {
          templateConfirmationMail {
            tideId
          }
        }
        contactDetails {
          tag {
            tideId
          }
        }
        form {
          privacyPage
        }
      }
    }
  }

  fragment fullscreenNavigationSectionFields on TideItemForNavigationSection {
    id
    childItems {
      id
      templateName
      ... on TideItemForNavigationSection {
        content {
          general {
            title
            text
            path
            url
          }
        }
        childItems {
          ...navigationLinkFields
        }
      }
    }
  }

  fragment navigationLinkFields on TideItemForNavigationLink {
    id
    templateName
    name
    content {
      general {
        title
        icon
        path
        url
      }
    }
  }

  fragment uspGroupFields on TideItemForUspGroup {
    id
    content {
      general {
        title
      }
    }
    childItems {
      id
      itemId
      templateName
      ... on TideItemForUsp {
        content {
          general {
            icon
            text
          }
        }
      }
    }
  }
`;

export default BookingPageTemplate;
