import React from "react";
import Icon from "../icon";
import { getChildNavigationLinks } from "../../utils";
import Link from "../link";
import { TideItemForNavigationSection } from "../../types";

export interface ContactNavigationProps {
  contactNavigationSection: TideItemForNavigationSection;
}

const ContactNavigation: React.FC<ContactNavigationProps> = ({ contactNavigationSection }) => (
  <div className="social-items">
    {getChildNavigationLinks(contactNavigationSection).map((navigationLink) => {
      const generalContent = navigationLink.content?.general;

      return (
        <Link
          href={generalContent?.url ?? ""}
          title={generalContent?.title ?? undefined}
          key={navigationLink.id}
          className="social-item"
        >
          <Icon name={generalContent?.icon ?? ""} />
        </Link>
      );
    })}
  </div>
);

export default ContactNavigation;
